import { Hero, HeroHeading, HeroLead, HeroButtons, HeroButton } from "src/components/Hero";
import { Features, Feature, FeatureHeading, FeatureText } from "src/components/Features";
import React from 'react';
export default {
  Hero,
  HeroHeading,
  HeroLead,
  HeroButtons,
  HeroButton,
  Features,
  Feature,
  FeatureHeading,
  FeatureText,
  React
};