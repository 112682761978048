// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-architecture-mdx": () => import("/opt/build/repo/website/src/pages/architecture.mdx" /* webpackChunkName: "component---src-pages-architecture-mdx" */),
  "component---src-pages-connect-component-mdx": () => import("/opt/build/repo/website/src/pages/connect-component.mdx" /* webpackChunkName: "component---src-pages-connect-component-mdx" */),
  "component---src-pages-document-processes-mdx": () => import("/opt/build/repo/website/src/pages/document-processes.mdx" /* webpackChunkName: "component---src-pages-document-processes-mdx" */),
  "component---src-pages-home-mdx": () => import("/opt/build/repo/website/src/pages/home.mdx" /* webpackChunkName: "component---src-pages-home-mdx" */),
  "component---src-pages-ci-with-travis-ci-mdx": () => import("/opt/build/repo/website/src/pages/ci-with-travis-ci.mdx" /* webpackChunkName: "component---src-pages-ci-with-travis-ci-mdx" */),
  "component---src-pages-how-do-websites-work-mdx": () => import("/opt/build/repo/website/src/pages/how-do-websites-work.mdx" /* webpackChunkName: "component---src-pages-how-do-websites-work-mdx" */),
  "component---src-pages-performance-testing-and-connection-pools-mdx": () => import("/opt/build/repo/website/src/pages/performance-testing-and-connection-pools.mdx" /* webpackChunkName: "component---src-pages-performance-testing-and-connection-pools-mdx" */),
  "component---src-pages-running-locally-mdx": () => import("/opt/build/repo/website/src/pages/running-locally.mdx" /* webpackChunkName: "component---src-pages-running-locally-mdx" */),
  "component---src-pages-setup-aws-infrastructure-mdx": () => import("/opt/build/repo/website/src/pages/setup-aws-infrastructure.mdx" /* webpackChunkName: "component---src-pages-setup-aws-infrastructure-mdx" */),
  "component---src-pages-setup-aws-account-mdx": () => import("/opt/build/repo/website/src/pages/setup-aws-account.mdx" /* webpackChunkName: "component---src-pages-setup-aws-account-mdx" */),
  "component---src-pages-setup-development-tools-mdx": () => import("/opt/build/repo/website/src/pages/setup-development-tools.mdx" /* webpackChunkName: "component---src-pages-setup-development-tools-mdx" */),
  "component---src-pages-setup-sequel-pro-mdx": () => import("/opt/build/repo/website/src/pages/setup-sequel-pro.mdx" /* webpackChunkName: "component---src-pages-setup-sequel-pro-mdx" */),
  "component---src-pages-setup-vscode-mdx": () => import("/opt/build/repo/website/src/pages/setup-vscode.mdx" /* webpackChunkName: "component---src-pages-setup-vscode-mdx" */),
  "component---src-pages-ci-and-kubernetes-mdx": () => import("/opt/build/repo/website/src/pages/ci-and-kubernetes.mdx" /* webpackChunkName: "component---src-pages-ci-and-kubernetes-mdx" */),
  "component---src-pages-development-process-mdx": () => import("/opt/build/repo/website/src/pages/development-process.mdx" /* webpackChunkName: "component---src-pages-development-process-mdx" */),
  "component---src-pages-building-glue-stack-mdx": () => import("/opt/build/repo/website/src/pages/building-glue-stack.mdx" /* webpackChunkName: "component---src-pages-building-glue-stack-mdx" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/website/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

